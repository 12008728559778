@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

html,
.radix-themes {
  font-family: "Inter", sans-serif !important;
}

a {
  cursor: pointer;
}

/* select {
  -webkit-appearance: none !important;
} */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #212133;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e7878;
  border-radius: 3500px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.left-panel {
  background: #1e1e2d;
  position: fixed;
  left: 0;
}

.left-menu {
  background: #1e1e2d;
}

.left-logo {
  padding: 21px;
  background: #1b1b28;
}

.left-logo a img {
  width: 3.4375rem;
  filter: invert(1) brightness(1);
  /* width: auto; */
}

.left-menu a {
  color: #9899ac;
  cursor: pointer;
}

.left-menu a span {
  align-items: center;
  font-size: 0.8125rem;
  font-weight: 600;
}

.left-menu a.active span {
  background: #1b1b28;
}

.left-menu a.active span i {
  color: #009ef7;
}

.left-menu a span i {
  font-size: 1.125rem;
}

.left-menu a:hover span {
  background: #1b1b28;
}

.left-menu a:hover span i {
  color: #009ef7;
}

.body-container {
  background: #151521;
  margin-left: 11rem;
  /* overflow: hidden; */
  /* padding-top: 20px; */
  /* height: 600px; */
}

.border-hover {
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.border-hover:hover {
  border: 1px solid #fff;
  cursor: pointer;
}

.adhaar-box {
  background: #212e48;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.adhaar-box:hover {
  border: 1px solid #fff;
  cursor: pointer;
}

.pan-box:hover {
  border: 1px solid #fff;
  cursor: pointer;
}

.dl-box:hover {
  border: 1px solid #fff;
  cursor: pointer;
}

.voter-box:hover {
  border: 1px solid #fff;
  cursor: pointer;
}

.inner-info img {
  width: 2.8125rem;
}

.inner-info h2 {
  color: #009ef7;
  font-size: 0.875rem;
  font-weight: 500;
}

.inner-info h3 {
  color: #009ef7;
  font-size: 2rem;
  font-weight: 500;
}

.verify-doc-box {
  border-radius: 0.475rem;
  /* width: 30%; */
}

.pan-box {
  background: #1c3238;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.pan-box .inner-info h2,
.pan-box .inner-info h3 {
  color: #50cd89;
}

.dl-box {
  background: #3a2434;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.dl-box .inner-info h2,
.dl-box .inner-info h3 {
  color: #f1416c;
}

.voter-box {
  background: #392f28;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.voter-box .inner-info h2,
.voter-box .inner-info h3 {
  color: #ffc700;
}

.verify-doc-wrapper {
  padding: 1.875rem 3.4375rem;
}

.profile-wrap {
  background: #1e1e2d;
  padding: 12px 25px;
}

.profile-wrap .dropdown-wrap button {
  align-content: center;
  align-items: center;
  background-color: #3ebfb9;
  display: flex;
  font-family: Poppins, sans-serif;
  justify-content: center;
  margin: 0;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 0.3125rem;
}

.dropdown-list {
  background: #1e1e2d;
  border-radius: 0.475rem;
  box-shadow: 0px 0px 1.875rem rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 999;
  right: 1.4375rem;
  top: 4rem;
  width: 16rem;
}

.dropdown-list a {
  color: #6d6d80;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.75rem 1.125rem;
  border-top: 0.0625rem solid #2b2b40;
}

.generate-link-wrap {
  background: #1a1a27;
}

.dropdown-list a:first-child {
  border-top: none;
}

.page-title h2 {
  font-weight: 700;
  color: #fff;
}

.generate-link-box a {
  font-size: 0.8125rem;
  color: #009ef7;
  font-weight: 600;
}

.token-no {
  background: #2b2b40;
  border-radius: 0.375rem;
  color: #565674;
  padding: 0.3125rem 1rem;
  font-size: 0.75rem;
  font-weight: 600;
}

/* FOR CUSTOM SCROLLBAR */

.left-menu {
  /* max-height: 28.75rem; */
  max-height: 85vh;
  overflow-y: auto;
}

/* The emerging W3C standard
   that is currently Firefox-only */
/* .left-menu {
  scrollbar-width: thin;
  scrollbar-height: 0.5rem;
  scrollbar-color: #212e48 #ccc;
}


.left-menu::-webkit-scrollbar {
  width: 4px;
}

.left-menu::-webkit-scrollbar-track {
  background: #1e1e2d;
}

.left-menu::-webkit-scrollbar-thumb {
  background-color: #212e48;
  border-radius: 1.25rem;
  border: 3px solid 000;
} */

footer {
  background: #1b1b28;
}

.copyright {
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 400;
}

.copyright span {
  color: #565674;
  padding-right: 0.5rem;
}

.foot-menu {
  color: #6d6d80;
  font-size: 0.8125rem;
  font-weight: 400;
}

.pro-pic {
  padding: 0.375rem 0.9375rem 1rem;
}

.pro-pic>span {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 0.3125rem;
  background: #3ebfb9;
}

.pro-pic>div h2 {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}

.pro-pic>div p {
  font-size: 13px;
  font-weight: 600;
  color: #565674;
}

.token-box {
  border-radius: 0.475rem;
  border: 0.0625rem dashed #323248;
  padding: 0.4375rem 0.9375rem;
}

.token-box p {
  color: #92929f;
  font-size: 0.85rem;
  margin: 0;
}

.token-box b {
  color: #50cd89;
  font-size: 1.075rem;
  font-weight: 700;
  margin: 0;
}

.generate-link-wrap {
  padding: 0.75rem 0.9375rem;
}

.document-type-box {
  background: #1e1e2d;
  border-radius: 0.3125rem;
  padding-bottom: 3.75rem !important;
}

.document-type-box h2 {
  color: #fff;
}

.verify-box {
  background: #1e1e2d;
  border-radius: 0.3125rem;
  /* margin-top: 1.5625rem; */
  padding: 2rem;
}

.verify-box h2 {
  font-size: 1.375rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2.75rem;
}

.adhaar-no-box {
  margin-top: 1.5625rem;
}

.adhaar-no-box h3 {
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
}

.adhaar-putin-box {
  margin-top: 0.625rem;
}

.adhaar-putin-box input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
}

.adhaar-putin-box input:focus {
  outline: none;
}

.adhaar-putin-box p {
  color: #565674;
  font-size: 0.8125rem;
  font-weight: 400;
}

.adhaar-putin-box button {}

.blue-bt {
  width: auto;
  height: 2.75rem;
  border-radius: 0.3125rem;
  background: #009ef7 !important;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 0 16px !important;
  text-transform: capitalize;
}

.tabs-titles button {
  font-size: 0.9375rem;
  font-weight: 700;
  color: #565674;
  padding-bottom: 1.0625rem;
}

.tabs-titles {
  position: relative;
  top: -2.4375rem;
  left: 1rem;
}

.tabs-titles button.active {
  color: #009ef7;
  border-bottom: 0.125rem solid #009ef7;
}

.digi-verify-bt {}

.veriication-detail-wrapper {
  background: #151521;
}

/* Document page */
input:focus,
select:focus {
  outline: none;
}

.filter-box {
  background: #1e1e2d;
  border-radius: 0.5rem;
  /* padding-bottom: 3.75rem !important; */
}

.filter-title h2 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.choose-doc-type select {
  background: #1b1b29;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
  width: 200px;
  margin: 0;
}

.choose-doc-type {
  padding: 0 0.75rem;
  background: #1b1b29;
  border-radius: 0.5rem;
}

.choose-doc-type input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
}

.serach-bt {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  background: #009ef7;
  color: #fff;
  text-transform: capitalize;
}

.refresh-bt {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  background: #212e48;
  color: #fff;
  text-transform: capitalize;
}

.reset-bt {
  width: auto;
  height: 42px;
  border-radius: 5px;
  background: #212e48;
  color: #fff;
  padding: 0 15px;
  font-size: 13px;
  color: rgb(0, 158, 247);
  text-transform: capitalize;
}

.search-input-box {
  position: relative;
}

.search-input-box input {
  padding-left: 2.8125rem !important;
}

/* .search-input-box input[type="date"]{
    padding-left: 10px;
} */
.search-input-box i {
  position: absolute;
  left: 15px;
  top: 13px;
  color: #565674;
}

.filter-date h2 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.filter-date input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
  width: 200px;
}

.all-verified-documents-wrap {
  background: #1e1e2d;
  border-radius: 0.5rem;
  padding-bottom: 1.75rem !important;
}

.all-verified-documents-wrap h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.all-verified-documents-wrap .text-red-400 {
  color: #fe0000;
}

.table-container table tr.head-bg {
  background: #2b2b40;
}

.table-container table tr.head-bg th:first-child,
.table-container table tr.head-dashed-underline th:first-child {
  text-align: left;
}

.table-container table tbody tr td:first-child {
  text-align: left;
}

.table-container table th {
  color: rgb(86, 86, 116);
  font-size: 0.8125rem;
  font-weight: 600;
  padding: 0.7rem;
  text-align: left;
}

.table-container table td {
  padding: 0.7rem;
  color: #fff;
  font-size: 0.8125rem;
  text-align: left;
}

.table-container table td.verified {
  color: #50cd89;
}

.table-container table td a.view,
.table-container table td button.view {
  width: 3.4375rem;
  height: 2.125rem;
  border-radius: 0.3125rem;
  background: #2b2b40;
  display: flex;
  margin: 0 auto;
}

.table-container table td a.delete-bt,
.table-container table td button.delete-bt {
  width: 3.4375rem;
  height: 2.125rem;
  border-radius: 0.3125rem;
  background: #f1416c;
  display: flex;
  margin: 0 auto;
  text-transform: capitalize;
}

.body-bg {
  background: #151521;
}

.assign-bt {
  height: 2.75rem;
  padding: 0 0.9375rem;
  border-radius: 0.3125rem;
  background: #212e48;
  color: #009ef7;
  font-size: 14px;
  text-transform: capitalize;
}

.convert-bt {
  height: 2.5rem;
  padding: 0 0.9375rem;
  border-radius: 0.3125rem;
  background: #009ef7;
  color: #fff;
  font-size: 0.875rem;
  text-transform: capitalize;
}

.already-client-bt {
  height: 1.5625rem;
  padding: 0 0.9375rem;
  background: #7239ea;
  font-weight: 600;
  color: #fff;
  font-size: 0.6875rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

.back-next-bt-wrapper div {
  height: 1.9375rem;
  padding: 0 0.5rem;
  font-weight: 700;
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: capitalize;
}

.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  border-bottom: 1px solid #2b2b40;
}

.react-responsive-modal-modal {
  background: #1e1e2d !important;
  border-radius: 0.5rem;
  padding: 0 !important;
  min-width: 800px;
}

.modal-body div span {
  color: #565674;
  font-size: 0.8125rem;
  font-weight: 600;
  width: 30%;
}

.modal-body div p {
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 600;
  width: 70%;
}

.react-responsive-modal-closeButton svg path {
  fill: #fff;
}

.modal-footer {
  border-top: 0.0625rem solid #2b2b40;
}

.modal-close-bt {
  height: 2.5rem;
  padding: 0 1.25rem;
  background: #009ef7;
  font-weight: 600;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

.avail-clients-h2 {
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
}

.avail-clients select {
  background: #1b1b29;


  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 0.875rem;
}

.avail-clients {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  width: fit-content;
}

.assign-clients-wrap {
  padding-top: 40px !important;
}

.no-active-client-found {
  background: #212e48;
  font-size: 0.8734rem;
  font-weight: 700;
  width: 43%;
  height: 3rem;
  color: #92929f;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  border: 1px dashed rgb(0, 158, 247);
}

.assign-bt {
  height: 2.6rem;
  padding: 0 1.25rem;
  background: #009ef7;
  font-weight: 600;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

.cancel-bt {
  height: 44px;
  padding: 0 1.25rem;
  background: rgb(43, 43, 64) !important;
  font-weight: 600;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

.convert-clients-wrap input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
}

.convert-clients-wrap select {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
}

.convert-clients-wrap input[type="file"] {
  height: 48px;
  line-height: 48px;
  padding-left: 0;
}

.convert-clients-wrap label {
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
}

.no-days span {
  color: #565674;
  font-size: 0.875rem;
  font-weight: 700;
  padding-top: 16px;
}

.no-days span h2 {
  color: #fff;
  padding-left: 5px;
}

.head-dashed-underline {
  border-bottom: 1px dashed #2b2b40;
}

.modal-body div span.inormation {
  width: 100%;
  font-size: 13px;
  margin-top: 5px;
  font-weight: 400;
  color: #565674;
}

div.document-type-pan {
  padding: 32px 30px !important;
}

.document-type-pan h2 {
  font-weight: 700;
}

.adhaar-putin-box {
  color: #fff;
}

/* .table-container th {
  white-space: nowrap;
} */

/* .table-container {
  width: 1040px;
  overflow: auto;
} */

.rdrCalendarWrapper {
  position: relative;
  border-radius: 0.6875rem;
  overflow: hidden;
  top: 0.4375rem;
}

.avl-token-wrap {
  border-radius: 0.475rem;
  border: 0.0625rem dashed #323248;
  padding: 0.4375rem 0.9375rem;
}

.avl-token-wrap h2 {
  color: #009ef7;
}

.total-spent-wrap h2 {
  color: #50cd89;
}

.avl-token-wrap span {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
}

.buy-coupons-wrapper h2 {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}

.buy-coupons-wrapper span {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  color: #f1416c;
  background-color: #3a2434;
  padding: 5px 12px;
  border-radius: 8px;
  margin-top: 12px;
}

.buy-token-box {
  background: #1e1e2d;
  border-radius: 0.5078rem;
}

.buy-token-box p {
  color: #565674;
  font-size: 15px;
}

.buy-token-box h2 {
  font-size: 26px;
  font-weight: 600;
}

.buy-token-box h3 {
  font-size: 17px;
  font-weight: 600;
}

.wallet-tabs {
  background: #1e1e2d;
  border-radius: 0.3125rem;
  padding: 23px 48px 0 !important;
}

.wallet-tabs button {
  font-size: 0.9375rem;
  font-weight: 700;
  color: #565674;
  padding-bottom: 1.0625rem;
}

.wallet-tabs .active {
  position: relative;
  color: #009ef7;
  border-bottom: 0.125rem solid #009ef7;
}

a.download {
  width: 5.4375rem;
  height: 2.125rem;
  border-radius: 0.3125rem;
  background: #2f9d1b;
  display: flex;
  margin: 0 auto;
}

.proile-pic-box {
  width: 80px;
  height: 80px;
  border-radius: 0.475rem;
  background-color: #3ebfb9;
  font-size: 47px;
}

.proile-name span {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.proile-options span {
  color: #474761;
  font-size: 14px;
  font-weight: 600;
}

button.update-bt {
  background: #6da95e;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
}

.settings-menu {
  width: 100px;
  height: auto;
}

.settings-menu button {
  font-size: 0.9375rem;
  font-weight: 700;
  color: #565674;
  padding-bottom: 1.0625rem;
}

.settings-menu button.active {
  color: #009ef7;
  border-bottom: 0.125rem solid #009ef7;
}

.proile-container {
  padding-bottom: 1.5rem !important;
}

.header-info h4 {
  font-size: 17px;
  font-weight: 700;
}

.header-info {
  border-bottom: 1px solid rgb(43, 43, 64);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.profile-details p {
  color: #565674;
  font-size: 13px;
  font-weight: 600;
}

.profile-details h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.profile-details {
  padding: 12px 0;
}

.verified {
  background: #50cd89;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 1px 5px;
}

.settings-form-label {
  width: 30%;
}

.settings-form-input-wrap {
  width: 70%;
}

.settings-form-input-wrap input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
  width: 50%;
}

.settings-input-wrap {
  margin-bottom: 20px;
}

.settings-form-label {
  font-size: 14px;
  font-weight: 600;
}

.save-changes-wrap {
  border-top: 1px solid rgb(43, 43, 64);
  padding-top: 15px;
  margin-top: 15px;
}

.sign-in-methods-list h4 {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.sign-in-methods-list p {
  font-size: 13px;
  font-weight: 600;
  color: #6d6d80;
}

.sign-in-methods-box {
  border-top: 1px dashed #323248;
  padding: 16px 0;
}

.sign-in-method-bt {
  background: #2b2b40;
  color: #7e8299;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 5px;
  text-transform: capitalize;
}

.email-change-modal {
  background: #1e1e2d;
  width: 500px;
  /* padding: 20px; */
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.modal-inner {
  width: 100%;
}

.mod-head span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.mod-head {
  border-bottom: 1px solid #2b2b40;
  margin-bottom: 12px;
  padding: 20px;
}

/* .modal-inner{
  width: 500px;
  background: #1e1e2d;
} */
.mod-title {
  color: #565674;
  font-size: 14px;
  font-weight: 600;
}

.mod-body {
  padding: 20px;
}

.mod-body input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
  margin-bottom: 12px;
}

.mod-foot {
  padding-top: 0;
  border-top: 1px solid #2b2b40;
  margin-bottom: 12px;
  padding: 20px 20px 0;
}

.mod-close {
  width: auto;
  height: 2.75rem;
  border-radius: 0.3125rem;
  background: #fff;
  color: #000;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 0 16px !important;
  text-transform: capitalize;
}

.reset-pass p {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 8px;
}

.reset-pass input {
  background: #1b1b29;
  border-radius: 0.5rem;
  padding: 0 0.75rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
  margin-bottom: 5px;
}

.passinfo {
  color: #565674;
  font-size: 13px;
  font-weight: 400;
}

.cancel-text {
  color: #78788d;
  font-size: 14px;
  font-weight: 500;
}

.profile-details-methods-main .sign-in-methods-box:first-child {
  border-top: none;
}

.verify-svg {
  color: #009ef7;
  position: relative;
  top: 3px;
  left: 5px;
}

.generate-new-coupon-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.customModal {
  min-width: 308px !important;
}

.login-box {
  width: 500px;
  margin: 0;

  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

}

.login-box-inner {
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 .1rem 1rem .25rem rgba(0, 0, 0, .05) !important;
}

.login-box input {
  height: 45px;
  background: rgb(232, 240, 254);
  border-radius: 8px;
  border: none;
  padding: 0 10px;
}

.login-box select {
  height: 45px;
  background: rgb(232, 240, 254);
  border-radius: 8px;
  border: none;
  padding: 0 15px;
  position: relative;
}

a.logo {
  width: 226px;
  height: 50px;
  margin: 25px auto 50px;
}

a.logo img {
  width: 100%;
}

.login-box.create-wrap {
  position: relative;
  transform: unset;
  transform: translateX(-50%);
  margin: 50px 0;
}

.pick-date-range {
  background: #1b1b29 !important;
  border-radius: 0.5rem !important;
  padding: 0 0.75rem !important;
  border: none !important;
  height: 3rem !important;
  color: #92929f !important;
  font-size: 14px !important;
  width: 200px;
}

.customer-list-title {
  font-size: 20px !important;
}

select {
  text-transform: capitalize !important;
}

.sales-home-top-box {
  background: #1e1e2d;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.svg-icon.svg-icon-info {
  color: #7239ea;
  background: #1b1b29;
  border-radius: 0.475rem;
  width: 50px;
  height: 50px;
}

.sales-home-top-box h2 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-align: right;
}

.sales-home-top-box p {
  font-size: 13px;
  font-weight: 500;
  color: #565674;
  text-align: right;
}

.svg-icon.svg-icon-2x svg {
  height: 2rem !important;
  width: 2rem !important;
}

.svg-icon.svg-icon-success {
  color: #50cd89;
}

.total-sales-revenue>p {
  color: #50cd89;
}

.verify-doc-wrapper.sales-home-3-box {
  padding: 1.875rem 30px 0;
}

.customer-details-top span h3 {
  color: #565674;
  font-size: 13px;
  font-weight: 700;
  width: 40%;
}

.customer-details-top span {
  margin-bottom: 20px;
}

.customer-details-top span h4 {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  width: 60%;
}

.customer-details-top span h4.text-red-600 {
  color: rgb(220, 38, 38);
  font-weight: 700;
}

.customer-details-top span h4.text-amber-300 {
  color: #ffc700;
}

/* star{
  background: #565674;
} */
.adhaar-data-box {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 700;
  color: #000;
  padding: 0 12px;
  margin: 25px 0;
}

back-bt {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 700;
  color: #000;
  padding: 0 12px;
  margin: 25px 0;
}

.react-responsive-modal-modal.notenoughTokenModal {
  max-width: 500px !important;
  min-width: unset;
}

.react-responsive-modal-modal.notenoughTokenModal h1 {
  padding: 10% 15%;
}

.react-responsive-modal-modal.veriyEmailModal {
  max-width: 500px !important;
  min-width: 500px;
  padding: 4rem 2rem 2rem 2rem !important;
}

input.add-email {
  background: #1b1b29;
  height: 45px;
  border-radius: 8px;
}

.display-wrap {
  background: #151521;
}

.react-responsive-modal-modal .display-wrap {
  background: unset;
}

.react-responsive-modal-modal .display-wrap .all-verified-documents-wrap {
  padding: 0;
}

.modal-footer button:first-child {
  order: 2;
}

.clients-docs-container .display-wrap .all-verified-documents-wrap {
  margin-left: 0;
  margin-right: 0;
}

.react-responsive-modal-modal.deleteModal {
  min-width: 500px;
}

.react-responsive-modal-modal.deleteModal .modal-footer {
  padding-bottom: 0;
  padding-right: 0;
}

:disabled {
  cursor: default !important;
  color: #ccc !important;
}

.datepicker-wrap {
  padding: 1.875rem 30px 0;
}

.tab-title button.active-tab {
  color: #009ef7;
}

.tab-title button {
  color: #565674;
}

.document-type-box-for-reseller {
  background: #1e1e2d;
  border-radius: 0.3125rem;
}

button.view-bt {
  background: #2b2b40;
  padding: 7px 12px;
  border-radius: 5px;
}

.used-by-left {
  width: 40%;
}

.used-by-right {
  width: 60%;
}

.all-invoice-small-box {
  border: 1px solid rgb(86, 86, 116);
}

.rt-PopoverContent {
  background: #fff !important;
  padding: 0 0 15px 0 !important;
}

.status-selector select {
  background: #1b1b29;
  border-radius: 0.5rem;
  border: none;
  height: 3rem;
  color: #92929f;
  font-size: 14px;
  width: 200px;
}

.status-selector {
  padding: 0 0.75rem;
  background: #1b1b29;
  border-radius: 0.5rem;
}

.copy-bt {
  color: #fff;
  font-weight: 700;
  padding: 5px 0;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable-bt {
  background: #353535;
  color: #fff;
  padding: 5px 0;
  border-radius: 5px;
  overflow: hidden;


}

.link-verify-page-wrap {
  /* width: 445px; */
  /* margin: 0 auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
}

.link-verify-page-wrap .verify-box {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.v-status {
  background: #ccc;
  padding: 5px 12px;
  border-radius: 5px;
  font-weight: 700;
}

.left-menu a {
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: center;
  align-items: center;

}

.left-menu a:hover span,
.left-menu a.active-nav span {
  color: #fff
}

.left-menu a.active-nav span i {
  color: #009ef7;
}

.left-menu a.active-nav span {
  background: #1b1b28;
}

/* @import "./responsive.css"; */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input.add-email {
  background: #1b1b29;
  height: 45px;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #000;
}

/* .outline-none {
  outline: none;
  outline-offset: 2px;
  box-shadow: unset;
} */
input.add-email {
  border: unset;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.table-container {
  overflow: auto;
}

.m-close-bt {
  display: none;
  position: absolute;
  right: 10px;
  top: 9px;
}

.ham-menu {
  display: none;
}
.ver-pan{
  height: 100%;
}
.rt-Button{
  -webkit-appearance: none !important;
}
.back-next-bt-wrapper .back-bt{
  background-color: rgb(43, 43, 64); color: rgb(126, 130, 153);    line-height: 29px;
}
.back-next-bt-wrapper .next-bt{
  background-color: rgb(43, 43, 64); color: rgb(126, 130, 153);    line-height: 29px;
}
.skip-invoice-serial-wrap{
  display: flex;
  justify-content: flex-start;
}
.veriyEmailModal span {
  color: #fff;
  text-align: center;
  margin-bottom: 12px;
  display: block;
}
.custom-content-wrap{
  min-height: 1000px;
}
.text-white.bg-mob.send-token-mob-wrapper {
  background: #151521;
}
.react-responsive-modal-modal.not.enough-token-modal {
  width: 450px !important;
  max-width: unset;
  min-width: unset;
}

.react-responsive-modal-modal.not.enough-token-modal h2 {
  padding: 15px 85px 35px;
  text-align: center;
  margin-top: 15px;
}
/* .blue-bt{
  font-weight: 600;
    padding: 0 16px !important;
    text-transform: capitalize;
    width: 100% !important;
    margin: 12px auto 25px !important;
    display: block;
} */
/* .ver-pan{
  height: 100vh !important;
} */
.profile-details .blue-bt {
  width: auto !important;
  margin: 0 0 0 !important;
  display: inline;
}
/* .blue-bt.ml span{
  left: 50%;
  margin-left: -15px;
} */

button.generate-new-coupon-title.blue-bt {
  width: auto !important;
  display: inline;
  margin: 0 !important;
}
.dvr-box{
  width: 36rem !important;
}
.enough-token-modal button.blue-bt {
	width: 92%;
	display: block;
	margin: 0 auto 18px;
}
.back-next-bt-wrapper div{
  cursor: pointer;
}
:disabled {
  cursor: default !important;
  color: #282837 !important;
}
.rt-reset.rt-BaseButton.rt-Button{
	height: 45px;
}
.edit-profile-section label span{
  color: #565674;
  font-size: 13px;
  font-weight: 600;
  width: 25%;
  display: inline-flex;
}
.edit-profile-section input{
  border: 1px solid #565674;
}
.edit-profile-section input{
  border: 1px solid #565674;
}
.edit-profile-section input {
  border: 1px solid #565674;
  font-size: 14px;
  padding: 5px;
}
.edit-profile-section select {
  border: 1px solid #565674;
  font-size: 14px;
  padding: 5px;
  width: 209px;
  color: #fff;
  background: #1E1E2D;
}
@media (max-width: 767px) {

  .reset-pass input {
    width: 90% !important;
  }
}