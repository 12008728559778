@media(min-width:2000px){
  .ver-pan {
    height: 100vh;
  }
}

@media(min-width: 1500px){
  .table-container table{
    width: 100%;
  }
}

@media (max-width: 992px) {
  .left-logo a img {
    width: 40px;
}
  .ham-menu{
    display: block;
    position: absolute;
    left: 15px;
  }
  .m-close-bt{
    display: block;
  }
  .left-panel {
    display: none;
  }

  .left-panel.open {
    display: block;
    z-index: 9;
  }
  .body-container {
    margin-left: 0;
  }
  .table-container {
    overflow: auto;
  }
  .ver-pan {
    height: 100% !important;
  }
}
@media (max-width:1000px) and (orientation: landscape) {
  .ver-pan {
  
    padding-top: 15px !important;
  }
}
@media (max-width: 767px) {
  .login-box {
    width: 300px;
  }
  .logo {
    width: 200px !important;
    height: auto;
  }
  .profile-wrap {
    width: 100% !important;
    padding: 12px !important;
  }

  .flex-wrap input {
    width: 100%;
  }
  .serach-bt {
    width: 100%;
  }
  .filter-date {
    width: 100%;
  }
  .rt-reset {
    width: 100%;
  }
  .refresh-bt {
    width: 100%;
  }
  .rt-r-display-flex .rt-reset {
    width: auto;
  }
  .rt-r-display-flex .rt-reset.pick-date-range {
    width: 100%;
  }
  .rt-r-jc-start {
    justify-content: flex-start;
    flex-direction: column;
  }
  .total-invoices-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }
  .total-invoices-wrap div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total-invoices-wrap .tsr-wrap {
    display: flex;
    flex-direction: column;
  }
  .c-m-title{
    display: flex;
    flex-direction: column;
  }
  .all-verified-documents-wrap-mob{
    padding: 16px;
    width: 89%;
    max-width: 100%;
  }
  .all-verified-documents-wrap-mob input{
    width: 100%;
  }
  .rq-mob-bt{
    width: 100%;
  }
  .bg-mob{
    background: #151521;
  }
  .all-verified-documents-wrap{
    /* margin: 20px !important; */
    padding: 20px;
  }
  .body-bg {
    background: #151521;
    padding: 20px !important;
    /* width: 85%; */
}
  .proile-options{
    display: flex;
    flex-direction: column;
  }
  .proile-pic-box{
    width: 40px;
    height: 40px;
    border-radius: 0.475rem;
    background-color: #3ebfb9;
    font-size: 25px;
  }
  .profile-details, .sign-in-methods-box {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left;
    justify-content: flex-start;
}
.w-1\/4 {
    width: 100%;
}
.profile-details .blue-bt{
margin-top: 10px;
}
.email-change-modal{
    width: 95%;
}
.reset-pass input{
    width: 90% !important;
}
.send-token-mob-wrapper .all-verified-documents-wrap{
     margin: 20px !important; 
    
  }
  .buy-tokens-wrapper{
    padding-bottom: 0;
    padding-top: 0;
}
.all-verified-documents-wrap-mob h1{
    font-size: 20px;
}
.verify-doc-wrapper {
  padding: 10px;
  background: #151521;
}
.search-input-box input{
  width: 100%;
}
.choose-doc-type{
  margin-bottom: 0.375rem;
  width: 100%;
}
.choose-doc-type select{
  width: 100%;
}
.react-responsive-modal-modal{
  max-width: unset;
  width: 90%;
  min-width: unset;
}
.search-input-box {
  position: relative;
  width: 100%;
}
.avl-token-wrap h2 {
  text-align: centerc;
  font-size: 0.875rem;
}
.avl-token-wrap span{
  font-size: 0.875rem;
}
.wallet-tabs button{
  font-size: 0.75rem;
  padding-bottom: 4px
}
.wallet-tabs{
  padding: 10px !important;
}
.filter-box{
  margin-bottom: 12px;
}
.rt-r-jc-start{
  width: 100%;
}
.status-selector{
  width: 100%;
}
.status-selector select{
  width: 100%;
}
.rt-reset {
  width: 100%;
  margin-top: 16px;
}
.react-responsive-modal-modal.not.enough-token-modal, .react-responsive-modal-modal.veriyEmailModal
{
  width: 90% !important;
  max-width: unset !important;
  min-width: unset !important
  
}
.dvr-box {
	width: 100% !important;
}
.ver-pan{
  width: 100%;
  padding: 12px !important;
  height: 100vh;
}
.dvr-box{
  width: 100%;
  padding: 12px !important;

}
.verify-box h2 {

	margin-bottom: 2.75rem;
	text-align: center;
}
.verify-box{
  display: flex;
  flex-direction: column;
}
}
